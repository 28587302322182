
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@layer base {
  html {
    font-family: "Mulish", system-ui, sans-serif;
    height: 100%;
    
  }

}

input{
  color-scheme: var(--color-scheme-background);
}


@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

